@import "../../common/styles/vars";

.organizationSavePage {
  display: flex;
  overflow: hidden;
  max-height: 100%;
  flex: 1;
  flex-flow: column nowrap;
  padding: px(16) 0 px(32);
  border-top: px(1) solid $gray-300;
  margin-top: px(16);

  .organizationSaveCols {
    display: flex;
    overflow: hidden;
    width: px(900);
    flex: 1;
    flex-flow: row nowrap;

    .organizationSaveCol {
      display: flex;
      overflow: hidden;
      height: 100%;
      flex-flow: column nowrap;
      overflow-y: auto;

      .organizationSaveColTitle {
        position: relative;
        z-index: 999;
        padding-bottom: px(16);
        background-color: $white;
        box-shadow: 0 px(2) px(4) rgb(0 0 0 / 10%);
        color: $gray-700;
        font-size: px(16);
      }

      .organizationSaveForm {
        position: relative;
        overflow: hidden;
        width: 100%;
        flex: 1;
        padding: px(16) px(50) px(16) px(8);
        overflow-y: auto;
        transform: translateY(px(-8));
      }

      &.basicInformation {
        width: 45%;
      }

      &.providersInformation {
        flex: 1;
        margin-left: px(32);
      }
    }
  }

  .organizationSaveActions {
    display: flex;
    width: px(900);
    flex: 0 auto;
    justify-content: flex-end;
    margin-top: px(16);
  }
}

.searchBar {
  margin-bottom: px(12);
}

.checkboxGroup {
  :global {
    .ant-checkbox-wrapper {
      display: flex !important;
      width: 100%;
      margin: px(12) 0 !important;
    }
  }
}

.adAccount {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  padding: px(8);
  border-left: px(4) solid transparent;
  margin: px(16) 0;
  background: $gray-100;
  border-radius: px(4);
  box-shadow: 0 0 px(4) rgba(0 0 0 / 10%);

  &.newAdAccount {
    border-left-color: $orange;
  }

  .adAccountInformation {
    flex: 1;
    margin-right: px(8);

    .adAccountId {
      font-size: px(12);
      font-weight: 600;
      opacity: 0.5;
    }
  }
}
