/* stylelint-disable no-descending-specificity */
@import "../../common/styles/vars.scss";

$margin-lr: px(12);

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  &.expanded {
    .sidebarLettering {
      display: flex;
      transform: none;
    }

    .sidebarLogo {
      img {
        width: px(60);
      }
    }

    .sidebarContent {
      margin-top: 0;

      .menuElement {
        padding: 0 $margin-lr;

        .menuElementContainer {
          width: 100%;
          height: auto;
          padding: px(4) px(10);
          border-radius: px(8);

          .menuElementIcon {
            width: auto;
            margin-right: px(0);
            font-size: px(14);
          }

          .menuElementLabel {
            transform: scaleY(1);
          }
        }
      }
    }
  }

  .sidebarLogo {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: px(20) $margin-lr;

    img {
      width: px(30);
      transition: $smooth-transition-quick;
    }
  }

  .sidebarContent {
    overflow: hidden;
    width: 100%;
    flex: 1;
    margin-top: px(30);
    overflow-y: auto;
    transition: $smooth-transition-quick;

    .menuElement {
      width: 100%;
      padding: 0 px(4);
      margin-bottom: px(10);
      transition: $smooth-transition-quick;

      .menuElementContainer {
        position: relative;
        display: flex;
        overflow: hidden;
        width: px(30);
        height: px(30);
        align-items: center;
        margin: 0 auto;
        background-color: var(--main-gray-100-color);
        border-radius: 50%;
        color: var(--main-gray-700-color);
        transition: $smooth-transition-quick;

        .menuElementIcon {
          display: flex;
          width: px(30);
          align-items: center;
          justify-content: center;
          font-size: px(16);
          transition: $smooth-transition-quick;

          > span {
            width: px(30);
          }
        }

        .menuElementLabel {
          flex: 1;
          font-size: px(14);
          font-weight: 500;
          transform: scaleY(0);
          transition: $smooth-transition-quick;
          white-space: nowrap;
        }
      }

      &.active {
        .menuElementContainer {
          background-color: var(--main-secondary-alt-color);
          color: var(--main-dark-700-color);
        }
      }

      &:hover:not(.active) {
        .menuElementContainer {
          background-color: var(--main-gray-500-color);
          color: var(--main-dark-700-color);
          opacity: 0.6;
        }
      }
    }
  }

  .sidebarLettering {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: px(20);
    transform: translateX(-300%);
    transition: $smooth-transition-quick;

    img {
      width: 50%;
    }
  }
}
