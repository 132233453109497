@import "../../common/styles/vars.scss";

.imagePicker {
  position: relative;
  width: 100%;
  max-width: px(450);
  border: px(2) dotted var(--main-gray-500-color);

  .imagePickerContainer {
    position: relative;
    width: 100%;
    background: no-repeat center center;
    background-size: contain;
  }

  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: var(--main-gray-500-color);
    font-size: px(20);
  }
}
