@import "../../common/styles/vars";

.imageUpload {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;

  .imageUploadSpinner {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgb(10 10 10 / 20%);
    cursor: pointer;
  }

  .imageUploadActions {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(10 10 10 / 20%);
    opacity: 0%;
    transition: $smooth-transition-quick;

    .uploadTool {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      .imageUploadSelect {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        .imageUploadIcon {
          color: var(--main-light-color);
          font-size: px(20);
        }
      }

      :global {
        .ant-upload,
        .ant-upload-select,
        .ant-upload-select input {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &:hover {
    .imageUploadActions {
      opacity: 1;
    }
  }
}
