/* stylelint-disable color-no-hex */
@import "./utils";

/** Colors **/
$primary: #45354d;
$primary-light: #7a568b;
$secondary: #fff7cc;
$secondary-alt: #fdee9d;
$dark: #231425;
$dark-700: #352d36;
$light: #fdfcf6;
$almost-white: #f0f0f0;
$orange: #f2c43a;
$white: #fff;
$gray-700: #999;
$gray-500: #ccc;
$gray-300: #f5f5f5;
$gray-100: #f9f9f9;
$electric-blue: #0871d0;

$viz-blue-start: #1f203c;
$viz-blue-end: #101226;
$viz-light-blue-700: rgb(2 119 189 / 0.5);
$viz-light-blue-translucid: rgb(62 98 119 / 0.05);

$danger: #ff0e0e;
$success: #4bb543;
$success-light: #c7ebc4;
$neutral: #ebebeb;
$info: #6a7ad6;
$warning: #cccc4f;

$fb-blue:#1877f2;
$fb-blue-alt:#96aae0;

/** Units **/
$desktop-min-width: px(900);
$margin-lr: px(30);

/** Fonts **/
$main-font: "Source Sans Pro", sans-serif;

/** Misc **/
$browser-context: 0.0625;
$smooth-transition-quick: all 0.15s ease;
