@import "../../../common/styles/vars.scss";

.tableReport {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  padding-bottom: px(20);

  .downloadButton {
    margin-left: px(10);
  }

  .spacer {
    height: px(100);
  }

  .progress {
    display: flex;
    width: 100%;
    align-items: center;

    .progressLabel {
      width: px(40);
      color: var(--main-dark-color);
      font-size: px(12);
      font-weight: 600;
      text-align: right;
    }

    .progressChart {
      flex: 1;
      margin-right: px(4);

      .track {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: px(15);
        background-color: rgb(210 210 210 / 30%);
        border-radius: px(10);
        box-shadow: niceshadow(0.1);

        .thumb {
          position: absolute;
          left: 0;
          height: 100%;
          background-color: var(--main-orange-color);
        }
      }
    }
  }

  .kpiColumn {
    overflow: hidden;
    min-width: px(160);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .budgetColumn {
    overflow: hidden;
    min-width: px(120);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kpiTh {
    overflow: hidden;
    max-width: px(120);
    padding-right: px(6);
    color: var(--main-primary-alt-color);
    font-size: px(12);
    font-weight: 600;
    line-height: 1.1;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .breadcrumbs {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding-left: px(12);
    margin-right: px(8);

    .breadcrumb {
      display: flex;
      align-items: center;
      margin-right: px(8);
      color: var(--main-gray-700-color);
      cursor: pointer;
      font-weight: 500;

      span {
        display: block;
        overflow: hidden;
        max-width: px(200);
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.active {
        color: var(--main-primary-alt-color);
        font-weight: 600;
        pointer-events: none;
      }

      &:not(.active) {
        &::after {
          margin-left: px(8);
          content: "/";
        }
      }
    }
  }

  .kpiThHighlight {
    font-weight: 600;
    text-align: left;
  }

  .noContent {
    color: var(--main-gray-500-color);
  }

  .kpiBudget {
    color: var(--main-primary-color);
    font-weight: 600;
  }

  .actions {
    display: flex;

    .actionsBtn {
      &:not(:first-child) {
        margin-left: px(10);
      }
    }
  }

  .selectedRow {
    background: var(--main-gray-300-color) !important;

    :global(.ant-table-cell) {
      &:first-child {
        border-left: px(4) solid var(--main-orange-color);
      }

      background: var(--main-gray-300-color) !important;
    }
  }

  .nameCell {
    width: 100%;
    width: px(320);

    .name {
      display: flex;
      max-width: 100%;
      align-items: center;
      color: var(--main-primary-color);
      font-size: px(12);
      font-weight: 400;

      span {
        margin-right: px(12);
      }

      div {
        line-height: 1.2;
        word-break: break-all;
      }
    }

    .id {
      width: 100%;
      margin-top: px(6);
      color: var(--main-primary-light-color);
      font-size: px(12);
      font-weight: 600;

      span {
        margin-right: px(6);
      }
    }
  }
}
