@import "../../../common/styles/vars.scss";

.statusChecks {
  width: 100%;
  background-color: var(--main-light-color);

  .statusChecksTitle {
    font-size: px(24);
  }

  .statusChecksWrapper {
    margin-top: px(12);
    border-radius: px(6);
    box-shadow: niceshadow();
  }

  .statusChecksGlobal {
    display: flex;
    width: 100%;
    align-items: center;
    padding: px(12) px(10) px(12) px(24);
    border-top: px(4) solid var(--main-neutral-color);
    border-radius: 0  0 px(6) px(6);
    box-shadow: 0 px(6) px(8) rgba(0 0 0 / 5%);

    &.healthy {
      border-top-color: var(--main-success-color);
    }

    .statusChecksGlobalContainer {
      flex: 1;

      .statusChecksGlobalTitle {
        color: var(--main-primary-color);
        font-size: px(24);
      }
    }

    .statusChecksGlobalIcon {
      display: flex;
      width: px(60);
      height: px(60);
      align-items: center;
      justify-content: flex-end;
    }
  }
}
