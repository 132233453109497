@import "../../../common/styles/vars";

.rightPanel {
  position: relative;
  width: 0;
  background: linear-gradient(to bottom, var(--main-viz-blue-start), var(--main-viz-blue-end));
  box-shadow: inset px(10) 0 px(12) var(--main-dark-color);
  transition: $smooth-transition-quick;

  &.visible {
    width: px(500);
  }

  .suspense {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex-direction: column;

    .header {
      flex: 0 0 auto;
      padding: px(8) px(36);
    }

    .metricsContent {
      position: relative;
      flex: 1 1 auto;
      padding: 0 px(18);
      overflow-y: auto;

      .emptyMetrics {
        position: absolute;
        top: 50%;
        color: var(--main-light-color);
        transform: translateY(-60%);
      }
    }
  }

  .topActions {
    position: absolute;
    z-index: 10;
    top: px(10);
    left: 0;

    .closeAction {
      display: flex;
      width: px(24);
      height: px(24);
      align-items: center;
      justify-content: center;
      border: px(1) solid var(--main-primary-color);
      background: var(--main-dark-color);
      border-radius: 0 px(4) px(4) 0;
      box-shadow: px(2) px(2) px(4) var(--main-dark-color);
      color: var(--main-light-color);
      cursor: pointer;
    }
  }
}
