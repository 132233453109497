@import "../../common/styles/vars";

.profileSettings {
  width: 100%;
  background-color: var(--main-white-color);
  box-shadow: niceshadow();

  .profileSettingsTop {
    display: flex;
    width: 100%;
    align-items: center;
    padding: px(16);

    .profileSettingsAvatar {
      margin-right: px(10);
    }

    .profileSettingsBasic {
      flex: 1;

      .profileSettingsName {
        font-size: px(20);
      }

      .profileSettingsLink {
        height: auto;
        padding: px(2) 0;
        line-height: 1;
      }

      .profileSettingsInfo {
        display: flex;
        padding: px(2) 0;
        color: var(--main-gray-700-color);
        font-size: px(14);

        strong {
          margin-right: px(6);
        }
      }
    }
  }

  .tab {
    padding: 0 px(14);
  }

  .profileTabsLoader {
    position: relative;
    display: flex;
    height: px(200);
    align-items: center;
    justify-content: center;
  }

  .tabPane {
    padding: px(14);
  }
}
