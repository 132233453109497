@import "../../common/styles/vars.scss";

.table {
  display: flex;
  width: 100%;
  max-width: 92vw;
  height: 100%;
  flex: 1;
  flex-direction: column;

  .picker {
    min-width: px(200);
    margin-left: px(8);

    &.pickerDanger {
      color: var(--main-danger-color);
      font-weight: 600;

      :global {
        .ant-select-selector {
          border-color: var(--main-danger-color);
        }
      }
    }
  }

  .noBorderColumn {
    border-right: 0;

    :global {
      .ant-table-column-title {
        display: none;
      }

      .ant-table-filter-column {
        justify-content: center;
      }

      .ant-table-filter-trigger {
        margin: 0;
      }
    }

    &::before {
      display: none;
    }
  }

  .tableActions {
    .tableActionsBtn {
      margin-left: px(8);
    }
  }

  .radioBtn {
    :global {
      .ant-radio-button-wrapper {
        font-size: px(12);
      }
    }
  }

  .isFlagged {
    border: var(--main-orange-color);
    background-color: var(--main-orange-color);
  }

  .flaggedRow {
    :global {
      .ant-table-cell:first-child {
        border-left: 4px solid var(--main-orange-color);
      }
    }
  }

  .io {
    .ioInput {
      &:not(:focus) {
        border: 0;
        background: none;
      }
    }
  }

  .visibiltyCell {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .nameCell {
    width: 100%;

    .name {
      overflow: hidden;
      width: 100%;
      color: var(--main-primary-color);
      font-size: px(12);
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .id {
      width: 100%;
      margin-top: px(4);
      color: var(--main-primary-light-color);
      font-size: px(12);
      font-weight: 600;
    }
  }
}
