@import "../../common/styles/vars";

.moduleWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  padding: px(36) px(60) 0;

  .moduleHeader {
    display: flex;
    height: px(70);
    flex-wrap: wrap;
    align-items: center;

    .breadcrumb {
      width: 100%;
      margin-bottom: px(4);
    }

    .moduleLogo {
      position: relative;
      width: px(80);
      margin-right: px(4);
      filter: grayscale(1);

      .moduleLogoSrc {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: no-repeat center center;
        background-size: contain;
      }

      &::after {
        display: block;
        width: 100%;
        padding-top: 30%;
        content: "";
      }
    }

    .moduleTitle {
      color: var(--main-dark-color);
      font-size: px(22);
      font-weight: 400;
    }

    .moduleHeaderAppend {
      margin-left: px(12);
    }
  }

  .moduleContent {
    display: flex;
    max-width: calc(100% - #{px(30)});
    height: calc(100% - #{px(70)});
    flex: 1;
    flex-direction: column;
  }
}
