@import "../../common/styles/vars.scss";

.markupCell {
  width: 100%;

  .markupCellRow {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .markupValue {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: px(4);
    color: var(--dark-gray-700-color);
    font-size: px(12);
    line-height: 1;

    span {
      margin-right: px(4);
    }
  }

  .markupCellBefore {
    position: relative;
    z-index: 2;
    display: flex;
    width: px(25);
    height: px(32);
    align-items: center;
    justify-content: center;
    background-color: var(--main-gray-300-color);
    border-bottom-left-radius: px(2);
    border-top-left-radius: px(2);
    box-shadow: px(2) 0 px(4) rgba(0 0 0 / 10%);
    color: var(--main-gray-700-color);
  }

  .isNegative {
    color: var(--main-danger-color);
  }

  .markupCellInput {
    flex: 1;
    border: 0 !important;
    background-color: var(--main-white-color);
    border-bottom-left-radius: px(0);
    border-top-left-radius: px(0);
    box-shadow: px(2) 0 px(4) rgba(0 0 0 / 5%) !important;

    :global {
      .ant-input-number-handler-wrap {
        display: none !important;
      }
    }
  }

  .markupCellAfter {
    display: flex;
    align-items: center;
    padding-left: px(10);

    .saveButton {
      margin-left: px(6);

      .saveButtonBtn {
        &:not(:disabled) {
          border-color: var(--main-success-color);
          animation: pulse-green 4s infinite;
          background-color: var(--main-success-color);

          @keyframes pulse-green {
            0% {
              box-shadow: 0 0 0 0 rgb(75 181 67 / 70%);
              transform: scale(0.95);
            }

            70% {
              box-shadow: 0 0 0 10px rgb(75 181 67 / 0%);
              transform: scale(1);
            }

            100% {
              box-shadow: 0 0 0 0 rgb(75 181 67 / 0%);
              transform: scale(0.95);
            }
          }
        }
      }
    }
  }
}
