@import "../../../../common/styles/vars";

.wrapper {
  width: 100%;

  .container {
    display: flex;
    width: 100%;
    align-items: center;
    margin-right: px(10);
    background-color: var(--main-primary-color);
    border-radius: px(4);

    :global {
      .ant-select-arrow {
        color: var(--main-white-color);
      }

      .ant-select-selection-item {
        max-width: px(100);
        border: 0;
        background: var(--main-dark-color);
        color: var(--main-white-color);
      }

      .ant-select-selection-item-remove {
        display: none;
        color: var(--main-secondary-color);
      }
    }

    .label {
      padding-left: px(12);
      color: rgba(255 255 255 / 30%);
    }

    .select {
      width: 100%;
      flex: 1;
      background-color: transparent;
      border-radius: px(4);
    }
  }
}
