@import "../../common/styles/vars.scss";

.userInfoInline {
  display: flex;
  align-items: center;

  .userName {
    margin-right: px(10);
    color: var(--main-primary-color);
    font-size: px(16);
    font-weight: 600;
  }
}

.userActions {
  width: 100%;

  .userActionsDivider {
    margin: px(4) 0;
  }

  .userActionBtn {
    width: 100%;
    padding-left: px(4) !important;
    color: var(--main-dark-color);
    font-weight: 500;
    text-align: left;

    &.active {
      color: var(--main-info-color);
      font-weight: 600;
    }
  }
}

.popover {
  min-width: px(240);

  :global {
    .ant-popover-inner {
      margin-right: px(10);
      background-color: var(--main-white-color);
      border-radius: px(10);
    }

    .ant-popover-arrow {
      display: none !important;
    }
  }
}
