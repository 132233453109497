@import "../../common/styles/vars.scss";

.table {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;

  .tableActions {
    .tableActionsBtn {
      margin-right: px(8);
    }
  }

  .nameCell {
    display: flex;
    align-items: center;

    .cellLogo {
      position: relative;
      width: px(60);
      filter: grayscale(1);

      .cellLogoBinder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: no-repeat left center;
        background-size: contain;
      }

      &::after {
        display: block;
        width: 100%;
        padding-top: 30%;
        content: "";
      }
    }

    .name {
      margin-left: px(8);
      color: var(--main-primary-color);
      font-size: px(18);
      font-weight: 400;
    }
  }
}
