@import "../../../common/styles/vars";

.dataProviderWrapper {
  width: 100%;
  margin-top: px(32);
  box-shadow: 0 0 px(6) rgba(0 0 0 / 10%);

  .dataProviderHead {
    display: flex;
    width: 100%;
    align-items: center;
    padding: px(8) px(16);
    background-color: $gray-300;
    box-shadow: 0 px(1) px(2) rgba(0 0 0 / 10%);
    cursor: pointer;

    .dataProviderHeadChevron {
      margin-right: px(16);
      color: $gray-700;
    }

    .dataProviderHeadLabel {
      display: flex;
      flex: 1;
      align-items: center;

      .dataProviderHeadLabelCounts {
        margin-left: px(4);
        opacity: 0.5;
      }

      .dataProviderHeadLabelIcon {
        width: px(20);
        margin-right: px(8);

        img,
        svg {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .dataProviderBody {
    overflow: hidden;
    max-height: px(500);
    padding: px(8) px(16);
    background-color: $white;
    overflow-y: auto;
  }
}
