@import "../../common/styles/vars.scss";

.currencyCell {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .label {
    padding: px(2) px(4);
    border: px(1) solid;
    border-radius: px(6);
    color: var(--main-gray-700-color);
  }

  .icon {
    margin-left: px(6);
    color: var(--main-gray-700-color);
  }
}

.modalInputContainer {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: px(18);
  }

  .modalInput {
    width: 100%;
  }

  .modalInputLabel {
    margin-bottom: px(6);
    color: var(--main-gray-700-color);
  }

  .modalTwoInput {
    display: flex;
    align-items: center;
    margin-bottom: px(6);

    .smallInput {
      width: px(100);
      margin-left: px(4);
    }
  }
}
