@import "../../common/styles/vars.scss";

.modal {
  width: px(600) !important;
  padding: px(30) 0;

  :global {
    .ant-modal-content {
      overflow: hidden;
      border-radius: px(10);
    }

    .ant-modal-body {
      padding: 0 !important;
    }

    .ant-modal-header {
      border-bottom: none;
    }
  }

  .headerActions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 0 px(24);

    .headerBtn {
      margin-left: px(10);
    }
  }

  .content {
    overflow: hidden;
    width: 100%;
    max-height: 60vh;
    padding: px(24);
    overflow-y: auto;

    .row {
      display: flex;
      align-items: center;
      margin: px(10) 0;

      .rowLabel {
        flex: 1;
      }
    }
  }
}
