@import "../../../common/styles/vars.scss";

.organizationPicker {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: px(20) px(36) px(20) px(16);
  background-color: var(--main-white-color);
  box-shadow: niceshadow(0.3);

  .adAccountPickerSelect {
    margin-top: px(20);
  }

  .organizationPickerSpinner {
    display: flex;
    width: 100%;
    height: px(80);
    align-items: center;
    justify-content: center;
  }

  .organizationPickerContainer {
    width: 100%;

    .organizationPickerSelect {
      width: 100%;
    }
  }

  :global {
    .ant-select-item-option-selected {
      background-color: var(--main-gray-300-color) !important;
    }
  }
}

.adAccountOption {
  display: flex;
  width: 100%;
  align-items: center;
}

.adAccountIcon {
  margin-right: px(12);
  color: var(--main-gray-700-color);
}
