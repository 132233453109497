@import "../../common/styles/vars.scss";

.platformWrapper {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  min-width: px(1600);
  height: 100%;
  flex-direction: column;

  .platformContainer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex-flow: row nowrap;

    .platformSidebar {
      position: relative;
      width: px(50);
      flex-shrink: 0;
      transition: $smooth-transition-quick;
    }

    .platformSidebarToggle {
      position: absolute;
      z-index: 999;
      bottom: px(50);
      left: 0;
      width: px(50);
      height: px(50);
      transform: translateX(-30%);

      .platformSidebarToggleBtn {
        border: 0;
        background-color: var(--main-white-color);
        border-radius: 50%;
        box-shadow: niceshadow(0.1);
        color: var(--main-dark-700-color);
      }
    }

    .platformContent {
      position: relative;
      z-index: 2;
      display: flex;
      flex: 1;
      flex-direction: column;
      background-color: var(--main-white-color);
      box-shadow: niceshadowx();

      .platformTopbar {
        position: relative;
        z-index: 2;
        overflow: hidden;
        height: px(60);
        box-shadow: niceshadow(0.5);
      }

      .platformChildren {
        display: flex;
        overflow: hidden;
        flex: 1;
        flex-direction: column;
        overflow-y: auto;

        > div:first-child {
          height: 100%;
          flex: 1;
        }

        .platformContentWrapper {
          display: flex;
          height: 100%;
          flex: 1;
          flex-direction: column;

          > div:first-child {
            height: 100%;
            flex: 1;
          }
        }
      }
    }
  }

  &.sidebarExtended {
    .platformContainer {
      .platformSidebar {
        width: px(180);
      }
    }
  }
}
