@import "../../../common/styles/vars.scss";

.suspense {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--main-light-color) / 70%);

  .suspenseContainer {
    display: flex;
    width: px(200);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .suspenseMessage {
      margin-top: px(10);
      line-height: 1.1;
      text-align: center;
    }
  }

  .suspenseLogo {
    position: absolute;
    right: px(30);
    bottom: px(20);
    width: px(60);
    animation: suspense-logo 1.5s;
    animation-fill-mode: both;

    @keyframes suspense-logo {
      from {
        opacity: 0%;
      }

      to {
        opacity: 0.1;
      }
    }

    img {
      width: 100%;
    }
  }
}
