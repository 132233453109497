@import "../../../common/styles/vars.scss";

.statusCheck {
  width: 100%;

  .statusCheckContainer {
    display: flex;
    width: 100%;
    align-items: center;
    padding: px(10) px(20);

    .statusCheckIcon {
      display: flex;
      width: px(40);
      height: px(40);
      align-items: center;
      justify-content: flex-end;
    }

    .definitionContainer {
      display: flex;
      flex: 1;
      align-items: center;
      margin-right: px(12);

      .definitionLogo {
        width: px(30);

        img {
          width: 100%;
        }
      }

      .definitionDes {
        flex: 1;
        margin-left: px(12);
        line-height: 1.15;

        .definitionTitle {
          color: var(--main-primary-light-color);
          font-size: px(18);
          font-weight: 500;
        }

        .definitionDescription {
          color: var(--main-gray-700-color);
          font-size: px(14);
        }
      }

      .definitionEffect {
        display: flex;
        align-items: center;
        margin-top: px(6);

        .definitionEffectIcon {
          margin-right: px(4);
          color: var(--main-warning-color);
        }

        .definitionEffectLabel {
          color: var(--main-warning-color);
          font-size: px(14);
          font-weight: 600;
        }
      }
    }
  }

  &:not(:last-of-type) {
    .statusCheckContainer {
      border-bottom: px(1) solid var(--main-almost-white-color);
    }
  }
}
