@import "../../common/styles/utils";

.LegalPage {
  width: 100%;
  padding: 24px;

  .container {
    width: 100%;
    color: var(--main-dark-color);
    max-width: 800px;
    margin: 0 auto;

    p, h3, h2, h1 {
      margin: 0 0 1rem;
    }

  }

}
