@import "../../common/styles/vars.scss";

:global {
  .ant-dropdown-menu-item-selected {
    background-color: var(--main-gray-100-color);
  }
}

.emptyContent {
  display: flex;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.tableHeader {
  display: flex;
  width: 100%;
  height: px(40);
  align-items: center;

  .tableHeaderLeft {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    padding-right: px(20);

    .lastUpdatedAt {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      color: var(--main-gray-700-color);

      &.hasBreadcrumbs {
        flex: unset;
        padding-right: px(20);
      }
    }
  }
}

.emptyRow {
  overflow: hidden;
  height: 0;
  visibility: hidden;
}

.search {
  position: relative;
  display: flex;
  width: px(300);

  .searchSpinner {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    display: flex;
    width: px(30);
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-left: px(4);
    color: var(--main-info-color);
  }
}

.tableContainer {
  height: calc(100% - #{px(40)});

  .table {
    :global {
      .ant-table {
        .ant-table-thead {
          .ant-table-cell {
            background-color: var(--main-white-color);
            font-size: px(15);
          }
        }

        .ant-table-row-selected > td {
          background-color: var(--main-gray-100-color);
        }
      }
    }
  }
}
