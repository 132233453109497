@import "../../../../common/styles/vars";

.metric {
  width: 100%;
  padding: 0 px(24);
  margin: px(24) 0;
  background: linear-gradient(to bottom, var(--main-viz-light-blue-700), var(--main-viz-light-blue-translucid));
  border-radius: px(6);
  box-shadow: 0 px(4) px(6) var(--main-dark-color);

  :global {
    .recharts-cartesian-axis-tick-value,
    .recharts-pie-label-text {
      fill: var(--main-secondary-color);
      font-size: px(10);
    }

    .recharts-tooltip-cursor {
      opacity: 0;
    }
  }

  .metricHeader {
    display: flex;
    width: 100%;
    align-items: center;
    padding: px(12) 0;
    cursor: pointer;

    .metricLabel {
      flex: 1;
      color: var(--main-white-color);
      font-weight: 600;
    }

    .metricToggle {
      padding: px(4);
      color: var(--main-white-color);
      transition: $smooth-transition-quick;
    }
  }

  .metricContent {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    padding: 0 0 px(12);
    transition: $smooth-transition-quick;

    .chart {
      width: 50%;
      padding-right: px(20);

      .chartLabel {
        color: var(--main-white-color);
        font-size: px(12);
        font-weight: 600;
        opacity: 0.9;
        text-transform: uppercase;
      }

      .chartContent {
        width: 100%;
        height: px(200);
        margin-top: px(12);
      }

      &.fullWidth {
        width: 100%;
        padding-right: 0;
      }

      &.extraHeight {
        .chartContent {
          height: px(350);
        }
      }
    }
  }

  &.expanded {
    .metricToggle {
      transform: rotate(180deg);
    }
  }

  &:not(.expanded) {
    .metricContent {
      height: 0;
      padding: 0;
    }
  }
}
