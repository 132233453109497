@import "../../../../common/styles/vars";

.fbChooseAdAccounts {
  display: block;
  animation: gradient-animation 15s ease infinite;
  background: linear-gradient(93deg, var(--main-fb-blue-color), var(--main-fb-blue-alt-color));
  background-size: 400% 400%;
  box-shadow: niceshadow(0.3);
  color: var(--main-color-white);

  .fbSpinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: px(48) px(32);
  }

  .fbInit {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: px(32);
    cursor: pointer;

    .fbLogo {
      width: px(40);

      img {
        width: 100%;
      }
    }

    .fbInitHelp {
      width: px(220);
      margin: px(12) 0;
      color: var(--main-white-color);
      font-weight: 500;
      line-height: 1.1;
      text-align: center;
    }
  }

  .fbChoosen {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: px(32) 0;
    cursor: pointer;
    transition: $smooth-transition-quick;

    .fbLogo {
      width: px(40);
      transition: $smooth-transition-quick;

      img {
        width: 100%;
      }
    }

    .fbInitHelp {
      width: px(220);
      margin: px(12) 0;
      color: var(--main-white-color);
      font-weight: 500;
      line-height: 1.1;
      text-align: center;
      transition: $smooth-transition-quick;
    }

    &:hover {
      .fbLogo,
      .fbInitHelp {
        opacity: 0.8;
      }
    }
  }
}

.fbInitSelected {
  width: 100%;
  max-height: px(300);
  padding: 0 px(46);
  overflow-y: auto;

  .account {
    display: flex;
    align-items: center;
    padding: px(6) px(12);
    margin: px(5) 0;
    background-color: var(--main-white-color);
    border-radius: px(6);
    box-shadow: niceshadow(0.2);

    .accountName {
      overflow: hidden;
      flex: 1;
      font-size: px(16);
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .accountRemove {
      border: 0;
      color: var(--main-danger-color);
    }
  }
}

.adAccountCell {
  .adAccountCellName {
    font-size: px(16);
  }

  .adAccountCellInfo {
    display: flex;

    span {
      margin-right: px(5);
    }
  }
}

.tableRow {
  cursor: pointer;
}

.fbChooseAccountsModal {
  width: 70vw !important;

  .fbChooseAccountsUser {
    display: flex;
    width: px(400);
    align-items: center;
    padding: px(10);
    border-top: px(4) solid var(--main-fb-blue-color);
    background: var(--main-gray-100-color);
    box-shadow: niceshadow(0.1);

    .fbChooseAccountsUserIcon {
      margin-right: px(14);
      color: var(--main-success-color);
      font-size: px(20);
    }

    .fbChooseAccountsUserContent {
      flex: 1;
      line-height: 1.1;

      .name {
        color: var(--main-dark-color);
        font-size: px(16);
      }

      .email {
        margin-top: px(2);
        color: var(--main-gray-700-color);
      }
    }
  }
}
