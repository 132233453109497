@import "../../common/styles/vars.scss";

.table {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;

  .tableActions {
    .tableActionsBtn {
      margin-left: px(8);
    }
  }

  .nameCell {
    display: flex;
    width: 100%;
    align-items: center;

    .nameCellAvatar {
      position: relative;
      overflow: hidden;
      width: px(50);
      height: px(50);
      flex-shrink: 0;
      margin-right: px(16);
      background: no-repeat center center;
      background-color: var(--main-orange-color);
      background-size: cover;
      border-radius: 50%;
      box-shadow: niceshadow(0.2);

      .nameCellAvatarImg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: no-repeat center center;
        background-size: cover;
      }
    }

    .nameCellContent {
      flex: 1;

      .nameCellContentName {
        display: flex;
        align-items: center;
        color: var(--main-gray-color);
        font-size: px(16);
      }

      .nameCellContentEmailBtn {
        height: auto;
        padding: 0;
        margin: 0;
        line-height: 1;
      }

      .nameCellStar {
        margin-left: px(6);
        color: var(--main-orange-color);
        font-size: px(14);
        transform: translateY(px(1));
      }
    }
  }

  .roleTag {
    padding: px(4) px(6);
    font-size: px(12);
    font-weight: 600;
    text-transform: uppercase;
  }
}
