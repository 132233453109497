@import "../../common/styles/vars.scss";

.formItem {
  margin-bottom: px(22);

  :global {
    .ant-form-item-label {
      label {
        font-size: px(16);
        font-weight: 500;
      }
    }
  }
}

.formItemSubmit {
  margin: px(44) 0 px(22);

  :global {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }
  }
}
