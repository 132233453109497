@import "../../common/styles/vars.scss";

.topbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--main-white-color);

  .topbarRow {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;

    .topbarRowLeft {
      padding-left: px(60);

      .brandLogo {
        position: relative;
        width: px(100);

        > div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: no-repeat center center;
          background-size: contain;
        }

        &::after {
          display: block;
          padding-top: 30%;
          content: "";
        }
      }
    }

    .topbarRowRight {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;
      padding-right: var(--main-desktop-margin-lr);
    }
  }

  .topbarUserInfo {
    display: flex;
    align-items: center;

    .userAvatar {
      display: flex;
      overflow: hidden;
      width: px(40);
      height: px(40);
      align-items: center;
      justify-content: center;
      padding: px(2);
      background:
        linear-gradient(
          var(--main-orange-color),
          var(--main-light-color),
        );
      border-radius: 50%;

      .userAvatarImage {
        width: 100%;
        height: 100%;
        background: no-repeat center center;
        background-color: var(--main-dark-color);
        background-size: cover;
        border-radius: 50%;
      }
    }
  }
}
