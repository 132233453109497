@import "../../common/styles/vars.scss";

.report {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  :global {
    .ant-pagination {
      display: none;
    }
  }

  .reportSuspense {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(245 245 245 / 60%);

    &.suspenseLight {
      background-color: rgba(245 245 245 / 60%);
    }
  }

  &.isPrivate {
    padding-top: px(60);
  }

  .topbar {
    position: relative;
    z-index: 3;
    width: 100%;
    height: px(50);
    background-color: var(--main-dark-color);
    box-shadow: niceshadow(0.9);
  }

  .cols {
    position: relative;
    display: flex;
    overflow: hidden;
    flex: 1;
    flex-wrap: nowrap;
    background-color: var(--main-white-color);

    .reportCol {
      width: 100%;
      height: 100%;

      &.reportColLeft {
        position: relative;
        z-index: 2;
        overflow: hidden;
        flex: 1;
        background-color: var(--main-white-color);
        overflow-x: auto;
      }

      &.reportColRight {
        background-color: var(--main-primary-color);
      }
    }
  }
}
