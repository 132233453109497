@import "../../../common/styles/vars.scss";

.Creative {
  img {
    display: block;
    width: 70px;
    height: 70px;
    object-fit: contain;
  }

  .cta {
    margin-top: 0.5rem;
  }
}
