@import "../../common/styles/vars.scss";

.newPassword {
  width: 100%;
  padding-right: px(40);

  .inputContainer {
    margin: px(10) 0;
  }
}
