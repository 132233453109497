@import "../../common/styles/vars.scss";

.newUserAlert {
  width: px(230);
  margin: px(12) 0;
}

.alert {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .alertTitle {
    margin-bottom: px(4);
    color: var(--main-gray-500-color);
    font-weight: 500;
  }

  .alertContents {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .name {
      color: var(--main-dark-color);
      font-size: px(20);
    }

    .email {
      color: var(--main-dark-color);
      font-size: px(16);
    }

    .password {
      display: flex;
      justify-content: center;

      span {
        margin-right: px(4);
      }
    }
  }
}
