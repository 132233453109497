@import "../../../common/styles/vars.scss";

.topbar {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 px(60);

  .range {
    border: 0;
    margin-right: px(10);
    background-color: var(--main-primary-color);
    border-radius: px(4);
    box-shadow: none;
    color: var(--main-white-color);
    outline: 0;

    :global {
      .ant-picker-suffix,
      .ant-picker-separator {
        color: var(--main-secondary-color);
      }

      .ant-picker-clear {
        background: var(--main-dark-color);
        color: var(--main-white-color);
      }

      .ant-picker-input {
        input {
          color: var(--main-white-color);
        }
      }
    }
  }

  .picker {
    display: flex;
    min-width: px(280);
    align-items: center;
    margin-right: px(10);
    background-color: var(--main-primary-color);
    border-radius: px(4);

    :global {
      .ant-select-selection-item {
        color: var(--main-white-color);
      }

      .ant-select-arrow {
        color: var(--main-white-color);
      }
    }

    &.pickerMulti {
      min-width: px(320);

      :global {
        .ant-select-selection-item {
          border: 0;
          background: var(--main-dark-color);
        }

        .ant-select-selection-item-remove {
          display: none;
          color: var(--main-secondary-color);
        }
      }
    }

    .pickerLabel {
      padding-left: px(12);
      color: rgba(255 255 255 / 30%);
    }

    .pickerSelect {
      width: 100%;
      flex: 1;
      border-radius: px(4);
    }
  }
}
