@import "../../common/styles/vars.scss";

.addUserBtn {
  display: block;
}

.avatarContainer {
  overflow: hidden;
  width: px(90);
  border-radius: 50%;
}
