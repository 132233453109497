@import "../../../common/styles/vars.scss";

.leftPanel {
  height: 100%;
  padding: px(30) px(60) 0;
  transition: $smooth-transition-quick;

  .tableContainer {
    padding-top: px(12);
  }

  .empty {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .header {
    display: flex;
    width: 100%;
    align-items: center;

    .headerLeft {
      flex: 1;
    }

    .headerRight {
      .lastUpdatedAt {
        padding-right: px(60);
        color: var(--main-gray-700-color);
        font-size: px(14);
        font-weight: 500;
      }
    }

    .logos {
      display: flex;
      align-items: baseline;

      .mainLogo {
        position: relative;
        width: px(150);

        .mainLogoBinder {
          position: absolute;
          width: 100%;
          height: 100%;
          background: no-repeat center center;
          background-size: contain;
        }

        &::after {
          display: block;
          width: 100%;
          padding-top: 30%;
          content: "";
        }
      }

      .powerLogo {
        display: flex;
        margin-left: px(6);

        .poweredBy {
          color: var(--main-gray-700-color);
          font-size: px(10);
          font-weight: 600;
          text-transform: uppercase;
        }

        .powerLogoBinder {
          width: px(50);
          margin-left: px(4);
          background: no-repeat center center;
          background-size: contain;

          &::after {
            display: block;
            width: 100%;
            padding-top: 30%;
            content: "";
          }
        }
      }
    }

    + div {
      height: 97%;
    }
  }
}
