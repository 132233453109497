@import "../../common/styles/vars.scss";

.userPicker {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: px(20) px(36) px(20) px(16);
  background-color: var(--main-white-color);
  box-shadow: niceshadow(0.3);

  .userPickerSpinner {
    display: flex;
    width: 100%;
    height: px(80);
    align-items: center;
    justify-content: center;
  }

  .userPickerContainer {
    width: 100%;

    .userPickerSelect {
      width: 100%;
    }

    .userPickerBtns {
      display: flex;
      align-items: center;
      margin-top: px(10);

      .btnAssignMyself {
        margin-left: px(10);
      }
    }
  }

  :global {
    .ant-select-item-option-selected {
      background-color: var(--main-gray-300-color) !important;
    }
  }
}
