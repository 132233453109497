@import "../../common/styles/vars";

.userAvatar {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(
      var(--main-orange-color),
      var(--main-light-color),
    );
  border-radius: 50%;

  .userAvatarImage {
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    background: no-repeat center center;
    background-color: var(--main-dark-color);
    background-size: cover;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  .userAvatarUpload {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
