@import "../../common/styles/vars";

.errorPage {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: var(--main-light-color);

  .footerAnimation {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 200px;
  }

  .errorContainer {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 0 calc(50% - #{px(250)});
    overflow-y: auto;

    .errorLogo {
      width: px(160);
      margin-top: px(60);
      margin-bottom: px(20);

      img {
        width: 100%;
      }
    }

    .errorTitle {
      color: var(--main-primary-color);
      font-size: px(80);
      font-weight: 800;
    }

    .errorDescription {
      margin-top: px(4);
      color: var(--main-dark-color);
      font-size: px(20);
    }

    .errorMoreDetails {
      margin-top: px(12);
      color: var(--main-dark-700-color);
      font-size: px(14);
      font-weight: 600;
    }

    .statusChecksWrapper {
      width: 100%;
      margin-top: px(12);
      margin-bottom: px(24);
    }
  }
}
