@import "../../common/styles/vars.scss";

.modal {
  padding: px(30) 0;

  &.default {
    width: px(500) !important;
  }

  &.wide {
    width: px(800) !important;
  }

  :global {
    .ant-modal-content {
      overflow: hidden;
      border-radius: px(10);
    }

    .ant-modal-body {
      padding-bottom: px(12);
    }

    .ant-modal-header {
      border-bottom: none;
    }
  }
}

.modalResponse {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: px(20);

  .modalResponseTitle {
    color: var(--main-primary-color);
    font-size: px(24);
  }

  .modalResponseCallToActions {
    margin-top: px(20);

    .callToAction {
      margin: 0 px(6);
    }
  }
}
