@import "../../../common/styles/vars.scss";

.accountPicker {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: px(20) px(36) px(20) px(16);
  background-color: var(--main-white-color);
  box-shadow: niceshadow(0.3);

  .accountPickerSpinner {
    display: flex;
    width: 100%;
    height: px(80);
    align-items: center;
    justify-content: center;
  }

  .accountPickerContainer {
    width: 100%;

    .accountPickerSelect {
      width: 100%;
    }
  }

  :global {
    .ant-select-item-option-selected {
      background-color: var(--main-gray-300-color) !important;
    }
  }
}
