@import "../../common/styles/vars.scss";

.table {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;

  .tableActions {
    display: flex;
    align-items: center;

    .tableActionsBtn {
      margin-right: px(8);

      &.tableActionsBtnReport {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-color: var(--main-electric-blue-color);
        background-color: var(--main-electric-blue-color);
      }
    }
  }

  .noContent {
    color: var(--main-gray-500-color);
    font-weight: 600;
  }

  .fbStats {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .fbStatsImg {
      margin-right: px(10);

      img {
        display: block;
        width: px(16);
      }
    }

    .fbStatsNames {
      overflow: hidden;
      flex: 1;
      font-size: px(14);
      line-height: 1.25;
    }

    .fbStatsCount {
      margin-left: px(6);
      color: var(--main-gray-700-color);
      font-size: px(16);
      font-weight: 600;
    }
  }

  .reportURL {
    .reportURLChip {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      border-radius: px(16);
      color: var(--main-electric-blue-color);
      cursor: pointer;
      font-weight: 600;

      .reportURLChipIcon {
        margin-right: px(6);
        opacity: 0.5;
      }

      div:not(.reportURLChipIcon) {
        overflow: hidden;
        max-width: px(150);
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .nameCell {
    display: flex;
    align-items: center;

    .cellLogo {
      position: relative;
      width: px(60);
      filter: grayscale(1);

      .cellLogoBinder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: no-repeat left center;
        background-size: contain;
      }

      &::after {
        display: block;
        width: 100%;
        padding-top: 30%;
        content: "";
      }
    }

    .name {
      margin-left: px(8);
      color: var(--main-primary-color);
      font-size: px(18);
      font-weight: 400;
    }
  }
}

.adAccountsCell {
  display: flex;

  .adAccountChip {
    display: inline-flex;
    align-items: center;
    padding: px(6) px(12);
    margin-right: px(12);
    background-color: var(--main-gray-100-color);
    border-radius: px(12);
    color: var(--main-gray-700-color);

    div {
      margin-left: px(8);
      color: var(--main-dark-700-color);
      font-weight: 600;
    }
  }
}
