@import "./vars";

.ant-select-item-option-selected,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--main-gray-300-color) !important;
}

.ant-modal-confirm-title {
  color: var(--main-dark-color);
  font-size: px(22) !important;
}

.ant-modal-confirm-content {
  font-size: px(16) !important;
}

.ant-modal-confirm-body {
  .anticon {
    transform: translateY(px(6));
  }
}

.ant-table-wrapper {
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      display: flex;
      height: 100%;
      flex-flow: column nowrap;

      .ant-table {
        overflow: hidden;
        flex: auto;

        table {
          height: 100%;
        }

        .ant-table-container {
          display: flex;
          height: 100%;
          flex-flow: column nowrap;

          .ant-table-content {
            overflow: hidden;
            height: 100%;
            overflow-y: auto;
          }

          .ant-table-header {
            flex: none;
            box-shadow: niceshadow(0.3);
          }

          .ant-table-body {
            overflow: scroll;
          }
        }
      }

      .ant-table-pagination {
        flex: none;
      }
    }
  }
}

.ant-picker-content {
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background-color: rgb(172 250 241 / 0.8);
  }
}

.ant-picker-ranges .ant-picker-preset > .ant-tag {
  border-color: $info !important;
  background-color: $info !important;
  color: $light !important;
}

:root {
  /** Colors: Identity **/
  --main-primary-color: #{$primary};
  --main-primary-light-color: #{$primary-light};
  --main-secondary-color: #{$secondary};
  --main-secondary-alt-color: #{$secondary-alt};
  --main-dark-color: #{$dark};
  --main-dark-700-color: #{$dark-700};
  --main-light-color: #{$light};
  --main-almost-white-color: #{$almost-white};
  --main-orange-color: #{$orange};
  --main-white-color: #{$white};
  --main-gray-700-color: #{$gray-700};
  --main-gray-500-color: #{$gray-500};
  --main-gray-300-color: #{$gray-300};
  --main-gray-100-color: #{$gray-100};
  --main-electric-blue-color: #{$electric-blue};
  --main-viz-blue-start: #{$viz-blue-start};
  --main-viz-blue-end: #{$viz-blue-end};
  --main-viz-light-blue-700: #{$viz-light-blue-700};
  --main-viz-light-blue-translucid: #{$viz-light-blue-translucid};

  /** Colors: Status **/
  --main-danger-color: #{$danger};
  --main-success-color: #{$success};
  --main-neutral-color: #{$neutral};
  --main-info-color: #{$info};
  --main-warning-color: #{$warning};

  /** Colors: Brands **/
  --main-fb-blue-color: #{$fb-blue};
  --main-fb-blue-alt-color: #{$fb-blue-alt};

  /** Units **/
  --main-desktop-min-width: #{$desktop-min-width};
  --main-desktop-margin-lr: #{$margin-lr};

  /** Fonts **/
  --main-font: #{$main-font};
}

html,
body {
  height: 100%;

  &,
  * {
    box-sizing: border-box;
    font-family: var(--main-font);
    text-rendering: optimizeLegibility;
  }
}
